
















































export const PopoverProps = {
  target: {
    type: String,
    required: true,
  },
  customClass: {
    type: String,
    required: false,
  },
};

import IButton from "@/interfaces/IButton";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  onBeforeUnmount,
  onMounted,
} from "@vue/composition-api";
import Button from "./Button.vue";

export default defineComponent({
  components: { Button },
  emits: ["primaryClick", "secondaryClick"],
  props: {
    ...PopoverProps,
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    primaryButton: {
      type: Object as PropType<IButton>,
      required: false,
    },
    secondaryButton: {
      type: Object as PropType<IButton>,
      required: false,
    },
    manual: {
      type: Boolean,
      default: false,
    },
    closeOnBlur: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: "px-4 py-3",
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const canClose = ref(true);

    root.$on("select2-opened", () => {
      canClose.value = false;
    });

    root.$on("select2-closed", () => {
      canClose.value = true;
    });

    const onKey = (e: KeyboardEvent) => {
      if (e.code == "Escape") {
        emit("input", false);
      }
    };

    onMounted(() => {
      window.addEventListener("keyup", onKey, false);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keyup", onKey);
    });
    return {
      canClose,
      shouldShow: computed<boolean>({
        get(): boolean {
          return props.value;
        },
        set(newValue: boolean): void {
          emit("input", newValue);
        },
      }),
    };
  },
});
