





























import IButton from "@/interfaces/IButton";
import { computed, defineComponent, ref } from "@vue/composition-api";
import InputGroup from "./InputGroup.vue";
import Popover, { PopoverProps } from "./Popover.vue";
import useEscapeHtml from "@/utilities/useEscapeHtml";

export default defineComponent({
  components: {
    Popover,
    InputGroup,
  },
  emits: ["confirmedDeletion"],
  props: {
    ...PopoverProps,
  },
  setup(_, { root, emit }) {
    //  Refs
    const show = ref(false);
    const action = ref("");

    var primaryClick: (() => void) | null = null;
    var secondaryClick: (() => void) | null = null;

    function confirmAction(mAction: string): Promise<boolean> {
      show.value = true;
      action.value = mAction;
      return new Promise(function (resolve, _) {
        primaryClick = () => {
          resolve(true);
          show.value = false;
        };

        secondaryClick = () => {
          resolve(false);
          show.value = false;
        };
      });
    }

    return {
      useEscapeHtml,
      show,
      confirmAction,
      action,
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.confirm"),
          variant: "danger",
          onClick: () => {
            if (primaryClick != null) primaryClick();
          },
        };
      }),
      secondaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.cancel"),
          variant: "outline-info",
          onClick: () => {
            if (secondaryClick != null) secondaryClick();
          },
        };
      }),
    };
  },
});
