















































import IMenu from "@/interfaces/IMenu";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import ConfirmActionPopup from "./ConfirmActionPopup.vue";
import useLowercased from "@/composables/useLowercased";

export default defineComponent({
  components: {
    ConfirmActionPopup,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    menuItems: {
      type: Array as PropType<IMenu[]>,
      default: () => [],
    },
    passthrough: {
      type: Object as PropType<unknown>,
      required: false,
    },
  },
  setup(props, { root }) {
    const confirmActionPopup = ref<InstanceType<typeof ConfirmActionPopup>>();

    async function itemClicked(menu: IMenu) {
      if (menu.requireConfirmation) {
        if (
          await confirmActionPopup.value?.confirmAction(
            menu.i18nAction
              ? `${root.$tc(menu.i18nAction)} ${useLowercased(menu.name)}`
              : menu.name
          )
        )
          menu.command(props.passthrough);
      } else {
        menu.command(props.passthrough);
      }
    }

    return {
      confirmActionPopup,
      itemClicked,
      useLowercased,
      disabledMessage: function (menu: IMenu) {
        if (menu.disabled && !menu.disabled(props.passthrough)) return "";
        if (
          menu.name.includes(root.$tc("common.delete")) ||
          menu.name.includes(root.$tc("common.remove"))
        ) {
          return root.$tc("tooltip.unauthorized_deletion");
        } else if (menu.name.includes(root.$tc("common.show"))) {
          return root.$tc("tooltip.unauthorized_access");
        } else {
          return "";
        }
      },
    };
  },
});
