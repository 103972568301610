export default function get(object: any, key: string) {
  const urlPattern = /\b(https?:\/\/.*?\.[a-z]{2,4}\/[^\s]*\b)/g;

  let result = object;

  if (!urlPattern.test(key)) {
    const keys = key.split(".");
    for (const key of keys) {
      result = result[key];
      if (result == undefined) {
        return undefined;
      }
    }
  } else {
    result = result[key];
    if (result == undefined) {
      return undefined;
    }
  }
  return result;
}
